import React, { useState, useEffect } from "react";
import Menu from "../componentes/menu";
import { API_URL, MSG_ERROR_COMUNICACION } from "../constants";

function Ranking() {
  const token = localStorage.getItem("token");
  const [ranking, setRanking] = useState([]);

  const [mensajeError, setMensajeError] = useState("");
  const [colorMensaje, setColorMensaje] = useState("");

  useEffect(() => {
    const obtenerRanking = async () => {
      setMensajeError("");
      setColorMensaje("");

      try {
        const headers = {
          "Content-Type": "application/json",
          ...(token && { Authorization: token }),
        };

        const response = await fetch(API_URL + "/ranking", {
          method: "GET",
          headers: headers,
        });

        if (response.status === 200) {
          const data = await response.json();
          setRanking(data);
        }
      } catch (error) {
        setMensajeError(MSG_ERROR_COMUNICACION);
        setColorMensaje("colorRojo");
        console.log(MSG_ERROR_COMUNICACION, error);
      }
    };
    obtenerRanking();
  }, [token]);

  return (
    <div className="Padre">
      <div className="ContenedorNav">
        <Menu></Menu>
      </div>

      <div className="Cuerpo">
        <div className="ContenedorRanking MargenMovil">
          <h1 className="titulo1">RANKING</h1>
          <p className={colorMensaje}>{mensajeError}</p>
          {ranking.map((grupo) => (
            <div className="ContenedorTabla">
              <h2 className="TituloFecha">
                {grupo.mes} de {grupo.anio}
              </h2>
              <table className="TablaRanking">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Posicion</th>
                  </tr>
                </thead>
                <tbody>
                  {grupo.usuarios.map((usuario) => (
                    <tr>
                      <td className={usuario.clase}>{usuario.nombre}</td>
                      <td>
                        {usuario.posicion}{" "}
                        <span className="colorVerde">
                          ({usuario.votosBueno})
                        </span>{" "}
                        <span className="colorRojo">({usuario.votosMalo})</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Ranking;
