import React, { useState, useEffect } from "react";
import { API_URL, MSG_ERROR_COMUNICACION } from "../../constants";

const TabGenerarCandidatos = () => {
  const token = localStorage.getItem("token");

  const [error, setError] = useState("");
  const [errorColor, setErrorColor] = useState("");

  const [mes, setMes] = useState("");
  const [anio, setAnio] = useState("");

  const listaMeses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  useEffect(() => {
    // Fecha
    const fecha = new Date();
    setMes(listaMeses[fecha.getMonth()]);
    setAnio(fecha.getFullYear());
  }, []);

  const generarCandidatos = async () => {
    setError("");
    setErrorColor("");

    try {
      const response = await fetch(API_URL + "/generarcandidatos", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.text();
        setError(data);
        setErrorColor("colorVerde");
        return;
      } else {
        const data = await response.text();
        setError(data);
        setErrorColor("colorRojo");
      }
    } catch (error) {
      console.error(MSG_ERROR_COMUNICACION, error);
      setError(MSG_ERROR_COMUNICACION);
      setErrorColor("colorRojo");
    }
  };

  return (
    <div className="SeccionPerfil">
      <h2>Generar candidatos</h2>

      <h3 className="centro">
        Candidatos de {mes} de {anio}
      </h3>

      <button
        className="BotonActualizar BotonGenerar"
        onClick={generarCandidatos}
      >
        Generar candidatos
      </button>

      <p className={`centro ${errorColor}`}>{error}</p>
    </div>
  );
};

export default TabGenerarCandidatos;
