import React, { useState, useEffect } from "react";
import { API_URL, MSG_ERROR_COMUNICACION } from "../../constants";
import { FcApproval, FcCancel } from "react-icons/fc";

const TabSolicitudNombres = () => {
  const token = localStorage.getItem("token");

  const [solicitudes, setSolicitudes] = useState([]);
  const [realizadas, setRealizadas] = useState([]);

  const [mensajeError, setMensajeError] = useState("");
  const [colorMensajeError, setColorMensajeError] = useState("");

  useEffect(() => {
    async function obtenerSolicitudes() {
      setMensajeError("");
      setColorMensajeError("");
      try {
        const response = await fetch(API_URL + "/solicitudes", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        if (response.status === 200) {
          const data = await response.json();
          setSolicitudes(data.pendientes);
          setRealizadas(data.solicitudes);
        } else {
          const data = await response.text();
          setMensajeError(data);
          setColorMensajeError("colorRojo");
        }
      } catch (error) {
        console.log("Error en la comunicación con el servidor: ", error);
        setMensajeError(MSG_ERROR_COMUNICACION);
        setColorMensajeError("colorRojo");
      }
    }

    obtenerSolicitudes();
  }, [token]);

  const gestionarSolicitud = (idSolicitud, aprobada) => async () => {
    setMensajeError("");
    setColorMensajeError("");

    try {
      const response = await fetch(API_URL + "/solicitudes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          solicitud: idSolicitud,
          aprobada: aprobada,
        }),
      });

      if (response.status === 200) {
        const data = await response.text();
        setSolicitudes(data.pendientes);
        setRealizadas(data.solicitudes);
      } else {
        const data = await response.text();
        setMensajeError(data);
        setColorMensajeError("colorRojo");
      }
    } catch (error) {
      console.log(MSG_ERROR_COMUNICACION, error);
      setMensajeError(MSG_ERROR_COMUNICACION);
      setColorMensajeError("colorRojo");
    }
  };

  return (
    <div className="SeccionPerfil">
      <h2>Solicitud de nombres</h2>

      <p className={`centro ${colorMensajeError}`}>{mensajeError}</p>

      <div className="SeccionPerfil">
        <h3>Solicitudes pendientes</h3>

        {solicitudes &&
          solicitudes.map((solicitud) => (
            <div>
              <div className="espacioperfil"></div>
              <table className="TablaRanking" key={solicitud.id}>
                <thead>
                  <tr>
                    <th>{solicitud.fechaSolicitud}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {solicitud.usuario} -&gt; {solicitud.nombreSolicitado}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button onClick={gestionarSolicitud(solicitud.id, true)}>
                        Aprobar <FcApproval />
                      </button>
                      <button onClick={gestionarSolicitud(solicitud.id, false)}>
                        Rechazar <FcCancel />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
      </div>

      <div className="espacioperfil"></div>

      <div className="SeccionPerfil">
        <h3>Solicitudes gestionadas</h3>

        {realizadas &&
          realizadas.map((solicitud) => (
            <div>
              <div className="espacioperfil"></div>

              <table className="TablaRanking" key={solicitud.id}>
                <thead>
                  <tr>
                    <th>{solicitud.fechaSolicitud}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {solicitud.usuario} -&gt; {solicitud.nombreSolicitado}
                    </td>
                  </tr>
                  <tr>
                    <td>{solicitud.aprobado ? "Aprobada" : "Rechazada"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TabSolicitudNombres;
