import React, { useState, useEffect } from "react";
import { API_URL, MSG_ERROR_COMUNICACION } from "../../constants";

const TabGenerarCRegistro = () => {
  const token = localStorage.getItem("token");

  const [mensajeError, setMensajeError] = useState("");
  const [colorMensajeError, setColorMensajeError] = useState("");

  const [codigoRegistro, setCodigoRegistro] = useState("");

  const [listaCodigos, setListaCodigos] = useState([]);

  useEffect(() => {
    async function obtenerCodigos() {
      setMensajeError("");
      setColorMensajeError("");

      try {
        const response = await fetch(API_URL + "/codigoregistro", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        if (response.status === 200) {
          const data = await response.json();
          setListaCodigos(data);
          return;
        } else {
          const data = await response.text();
          setMensajeError(data);
          setColorMensajeError("colorRojo");
        }
      } catch (error) {
        console.error(MSG_ERROR_COMUNICACION, error);
        setMensajeError(MSG_ERROR_COMUNICACION);
        setColorMensajeError("colorRojo");
      }
    }

    obtenerCodigos();
  }, [token]);

  const crearCodigo = async () => {

    setMensajeError("");
    setColorMensajeError("");

    if (codigoRegistro === "") {
      setMensajeError("El código de registro no puede estar vacío");
      setColorMensajeError("colorRojo");
      return;
    }

    try {
      const response = await fetch(API_URL + "/codigoregistro", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          codigo: codigoRegistro,
        }),
      });

      if (response.status === 200) {
        setCodigoRegistro("");
        setMensajeError("Código de registro creado correctamente");
        setColorMensajeError("colorVerde");
        setListaCodigos([...listaCodigos, { codigo: codigoRegistro, info: "No usado"}]);
      } else {
        const data = await response.text();
        setMensajeError(data);
        setColorMensajeError("colorRojo");
      }
    } catch (error) {
      console.error(MSG_ERROR_COMUNICACION, error);
      setMensajeError(MSG_ERROR_COMUNICACION);
      setColorMensajeError("colorRojo");
    }
  };

  return (
    <div className="SeccionPerfil">
      <h2>Código de registro</h2>

<div className="SeccionPerfil">


      <form
        onSubmit={(e) => {
          e.preventDefault();
          crearCodigo();
        }}
      >
        <input
          className="campoLogin"
          type="text"
          placeholder="Codigo de registro"
          value={codigoRegistro}
          onChange={(e) => setCodigoRegistro(e.target.value)}
        />
        <button className="BotonActualizar BotonGenerar" type="submit">
          Crear
        </button>
      </form>

      <p className={`centro ${colorMensajeError}`}>{mensajeError}</p>

      </div>

      {listaCodigos.map((codigo) => (
        <div>
          <div className="espacioperfil"></div>

          <table key={codigo.id} className="TablaRanking">
            <thead>
              <tr>
                <th>{codigo.codigo}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{codigo.info}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default TabGenerarCRegistro;
