import React, { useState, useEffect } from "react";
import Menu from "../componentes/menu";
import bcrypt from "bcryptjs";
import { API_URL, SALT, MSG_ERROR_COMUNICACION } from "../constants";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const [emailLogin, setEmailLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  const [errorLogin, setErrorLogin] = useState("");
  const [colorErrorLogin, setColorErrorLogin] = useState("");

  const [codigoRegistro, setCodigoRegistro] = useState("");
  const [nombreRegistro, setNombreRegistro] = useState("");
  const [emailRegistro, setEmailRegistro] = useState("");
  const [passwordRegistro, setPasswordRegistro] = useState("");
  const [errorRegistro, setErrorRegistro] = useState("");
  const [colorErrorRegistro, setColorErrorRegistro] = useState("");

  // Mandamos al usuario al perfil si está logueado
  useEffect(() => {
    if (token) {
      console.log("Usuario logueado, accediendo a perfil");
      navigate("/perfil");
    }
  }, [navigate, token]);

  const login = async () => {
    try {
      setErrorLogin("");
      setColorErrorLogin("");

      const hashedPassword = bcrypt.hashSync(passwordLogin, SALT);

      const response = await fetch(API_URL + "/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: emailLogin,
          password: hashedPassword,
        }),
      });

      if (response.status === 200) {
        const tokenSesion = await response.headers.get("authorization");

        localStorage.setItem("token", tokenSesion);
        navigate("/perfil");
        return;
      } else {
        setErrorLogin("Usuario o contraseña incorrectos");
        setColorErrorLogin("colorRojo");
        return;
      }
    } catch (error) {
      setErrorLogin(MSG_ERROR_COMUNICACION);
      setColorErrorLogin("colorRojo");
      console.error(MSG_ERROR_COMUNICACION, error);
    }
  };

  const registro = async () => {
    try {
      setErrorRegistro("");
      setColorErrorRegistro("");

      // Generamos SHA256 de la contraseña
      const hashedPassword = bcrypt.hashSync(passwordRegistro, SALT);

      if (passwordRegistro.length < 4) {
        setErrorRegistro("Contraseña: mínimo cuatro caracteres");
        setColorErrorRegistro("colorRojo");
      }

      const response = await fetch(API_URL + "/registro", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          codigoRegistro: codigoRegistro,
          nombre: nombreRegistro,
          email: emailRegistro,
          password: hashedPassword,
        }),
      });

      if (response.status === 200) {
        const data = await response.text();
        setErrorRegistro(data);
        setColorErrorRegistro("colorVerde");

        // Limpiamos los campos
        setCodigoRegistro("");
        setNombreRegistro("");
        setEmailRegistro("");
        setPasswordRegistro("");

        return;
      } else {
        const data = await response.text();
        setErrorRegistro(data);
        setColorErrorRegistro("colorRojo");
        return;
      }
    } catch (error) {
      console.error("Error al comunicar con el servidor", error);
      setErrorRegistro("Error al registrarse");
      setColorErrorRegistro("colorRojo");
      return;
    }
  };

  return (
    <div className="Padre">
      <div className="ContenedorNav">
        <Menu></Menu>
      </div>

      <div className="Cuerpo">
        <div className="ContenedorLogin">
          <h2>Inicio de sesión</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              login();
            }}
          >
            <input
              className="campoLogin"
              type="email"
              placeholder="Correo electrónico"
              value={emailLogin}
              onChange={(e) => setEmailLogin(e.target.value)}
            />
            <input
              className="campoLogin"
              type="password"
              placeholder="Contraseña"
              value={passwordLogin}
              onChange={(e) => setPasswordLogin(e.target.value)}
            />
            <button className="BotonLogin" type="submit">
              Iniciar sesión
            </button>
          </form>

          <p className={`msgErrorLogin ${colorErrorLogin}`}>{errorLogin}</p>
        </div>

        <div className="espaciomini"></div>

        <div className="ContenedorLogin">
          <h2>Registro</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              registro();
            }}
          >
            <input
              className="campoLogin"
              type="text"
              placeholder="Código de registro"
              value={codigoRegistro}
              onChange={(e) => setCodigoRegistro(e.target.value)}
            />
            <input
              className="campoLogin"
              type="text"
              placeholder="Nombre"
              value={nombreRegistro}
              onChange={(e) => setNombreRegistro(e.target.value)}
            />
            <input
              className="campoLogin"
              type="email"
              placeholder="Correo electrónico"
              value={emailRegistro}
              onChange={(e) => setEmailRegistro(e.target.value)}
            />
            <input
              className="campoLogin"
              type="password"
              placeholder="Contraseña"
              value={passwordRegistro}
              onChange={(e) => setPasswordRegistro(e.target.value)}
            />
            <button className="BotonLogin" onClick={async () => {}}>
              Registrarse
            </button>
          </form>
          <p className={`msgErrorLogin ${colorErrorRegistro}`}>
            {errorRegistro}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
