import React, { useState } from "react";
import TabGenerarCandidatos from "../pantallas/tabsAdmin/TabGenerarCandidatos";
import TabGenerarResultados from "../pantallas/tabsAdmin/TabGenerarResultados";
import TabGenerarCRegistro from "../pantallas/tabsAdmin/TabGenerarCRegistro";
import TabUsuarios from "../pantallas/tabsAdmin/TabUsuarios";
import TabSolicitudNombres from "../pantallas/tabsAdmin/TabSolicitudNombres";

import { FaCrown, FaUserAlt } from "react-icons/fa";
import { GrTableAdd } from "react-icons/gr";
import { RiCoupon2Fill } from "react-icons/ri";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";

const TabsAdmin = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const renderContent = () => {
    switch (activeTab) {
      case "tab1":
        return <TabGenerarResultados />;
      case "tab2":
        return <TabGenerarCandidatos />;
      case "tab3":
        return <TabGenerarCRegistro />;
      case "tab4":
        return <TabUsuarios />;
      case "tab5":
        return <TabSolicitudNombres />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div
        className="SeccionPerfil"
        style={{ display: "flex", cursor: "pointer", justifyContent: "center" }}
      >
        <div
          className="botonTab"
          onClick={() => setActiveTab("tab1")}
          style={{ margin: "0 5px" }}
        >
          <FaCrown />
        </div>
        <div
          className="botonTab"
          onClick={() => setActiveTab("tab2")}
          style={{ margin: "0 5px" }}
        >
          <GrTableAdd />
        </div>
        <div
          className="botonTab"
          onClick={() => setActiveTab("tab3")}
          style={{ margin: "0 5px" }}
        >
          <RiCoupon2Fill />
        </div>
        <div
          className="botonTab"
          onClick={() => setActiveTab("tab4")}
          style={{ margin: "0 5px" }}
        >
          <FaUserAlt />
        </div>
        <div
          className="botonTab"
          onClick={() => setActiveTab("tab5")}
          style={{ margin: "0 5px" }}
        >
          <MdOutlineDriveFileRenameOutline />
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>{renderContent()}</div>
    </div>
  );
};

export default TabsAdmin;
