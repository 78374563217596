import React, { useState, useEffect } from "react";
import { API_URL, MSG_ERROR_COMUNICACION } from "../../constants";

const TabGenerarResultados = () => {
  const token = localStorage.getItem("token");
  const [valoresAnio, setValoresAnio] = useState([]);

  const [mes, setMes] = useState(1);
  const [anio, setAnio] = useState("");
  const [errorGenerar, setErrorGenerar] = useState("");
  const [errorGenerarColor, setErrorGenerarColor] = useState("");

  useEffect(() => {
    async function obtenerAnios() {
      setErrorGenerar("");
      setErrorGenerarColor("");

      try {
        const response = await fetch(API_URL + "/valoresresultados", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          const data = await response.json();
          setValoresAnio(data);
          setAnio(data[0]);
          return;
        }
      } catch (error) {
        console.error("Error obteniendo años", error);
        setErrorGenerar(MSG_ERROR_COMUNICACION);
        setErrorGenerarColor("colorRojo");
      }
    }

    obtenerAnios();
  }, []);

  const generarResultados = async () => {
    setErrorGenerar("");
    setErrorGenerarColor("");

    try {
      console.log("Token: ", token);
      console.log("Body: ", JSON.stringify({ anio: anio, mes: mes }));

      const response = await fetch(API_URL + "/ranking", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          anio: anio,
          mes: mes,
        }),
      });

      const data = await response.text();
      if (response.status === 200) {
        setErrorGenerarColor("colorVerde");
        setErrorGenerar(data);
      } else {
        setErrorGenerar(data);
        setErrorGenerarColor("colorRojo");
      }
    } catch (error) {
      console.error(MSG_ERROR_COMUNICACION, error);
      setErrorGenerar(MSG_ERROR_COMUNICACION);
      setErrorGenerarColor("colorRojo");
    }
  };

  return (
    <div className="SeccionPerfil">
      <h2>Generar resultados</h2>
      <div className="ContenedorFecha">
        <select
          className="InputPerfil InputFecha"
          onChange={(e) => setMes(e.target.value)}
        >
          <option value="1">Enero</option>
          <option value="2">Febrero</option>
          <option value="3">Marzo</option>
          <option value="4">Abril</option>
          <option value="5">Mayo</option>
          <option value="6">Junio</option>
          <option value="7">Julio</option>
          <option value="8">Agosto</option>
          <option value="9">Septiembre</option>
          <option value="10">Octubre</option>
          <option value="11">Noviembre</option>
          <option value="12">Diciembre</option>
        </select>

        <select
          className="InputPerfil InputFecha EspacioFechas"
          onChange={(e) => setAnio(e.target.value)}
        >
          {valoresAnio.map((anio, index) => (
            <option key={index} value={anio}>
              {anio}
            </option>
          ))}
        </select>
      </div>
      <button
        className="BotonActualizar BotonGenerar"
        onClick={generarResultados}
      >
        Generar
      </button>
      <p className={`ErrorGenerar ${errorGenerarColor}`}>{errorGenerar}</p>
    </div>
  );
};

export default TabGenerarResultados;
