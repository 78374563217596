import React, { useState, useEffect } from "react";
import { API_URL, MSG_ERROR_COMUNICACION } from "../../constants";

const TabUsuarios = () => {
  const token = localStorage.getItem("token");

  const [usuarios, setUsuarios] = useState([]);
  const [error, setError] = useState("");
  const [errorColor, setErrorColor] = useState("");

  useEffect(() => {
    async function obtenerUsuarios() {
      setError("");
      setErrorColor("");

      try {
        const response = await fetch(API_URL + "/usuarios", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        if (response.status === 200) {
          const data = await response.json();
          setUsuarios(data);
          return;
        } else {
          const data = await response.text();
          setError(data);
          setErrorColor("colorRojo");
        }
      } catch (error) {
        console.error(MSG_ERROR_COMUNICACION, error);
        setError(MSG_ERROR_COMUNICACION);
        setErrorColor("colorRojo");
      }
    }

    obtenerUsuarios();
  }, [token]);

  return (
    <div className="SeccionPerfil">
      <h2>Usuarios: {usuarios.length}</h2>
      <p className={`centro ${errorColor}`}>{error}</p>
      {usuarios.map((usuario) => (
        <div>
          <div className="espacioperfil"></div>

          <table className="TablaRanking" key={usuario.id}>
            <thead>
              <tr>
                <th>{usuario.nombre}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Email: {usuario.email}</td>
              </tr>
              <tr>
                <td>C. Reg: {usuario.codigoRegistro}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default TabUsuarios;
