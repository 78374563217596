import React, { useState, useEffect } from "react";
import Menu from "../componentes/menu";
import { useNavigate } from "react-router-dom";
import { API_URL, MSG_ERROR_COMUNICACION } from "../constants";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";

function Votar() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [semana, setSemana] = useState(0);
  const [votosPDisponible, setVotosPDisponible] = useState(0);
  const [votosNDisponible, setVotosNDisponible] = useState(0);
  const [candidatos, setCandidatos] = useState([]);
  const [mensajeError, setMensajeError] = useState("");
  const [colorMensaje, setColorMensaje] = useState("");

  useEffect(() => {
    if (!token) {
      navigate("/login");
      return;
    }
  }, [navigate, token]);

  useEffect(() => {
    // Traemos info para votar
    const obtenerDatos = async () => {
      setMensajeError("");
      setColorMensaje("");

      try {
        const response = await fetch(API_URL + "/voto", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        if (response.status === 200) {
          const data = await response.json();

          setSemana(data.semana);
          setVotosPDisponible(data.votosBueno);
          setVotosNDisponible(data.votosMalo);
          setCandidatos(data.candidatos);
        } else {
          setMensajeError("Error al obtener la información");
          setColorMensaje("colorRojo");
        }
      } catch (error) {
        console.log(MSG_ERROR_COMUNICACION, error);
        setMensajeError("Error al obtener la información");
        setColorMensaje("colorRojo");
      }
    };

    obtenerDatos();
  }, [token]);

  const votar = async (usuario, isBueno) => {
    setMensajeError("");
    setColorMensaje("");

    try {
      const response = await fetch(API_URL + "/voto", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          idCandidato: usuario,
          votoBueno: isBueno,
        }),
      });

      const data = await response.text();
      if (response.status === 200) {
        setColorMensaje("colorVerde");
        setMensajeError(data);

        if (isBueno === true) {
          setVotosPDisponible(votosPDisponible - 1);
        } else {
          setVotosNDisponible(votosNDisponible - 1);
        }
      } else {
        setColorMensaje("colorRojo");
        setMensajeError(data);
      }
    } catch (error) {
      setMensajeError(MSG_ERROR_COMUNICACION);
      setColorMensaje("colorRojo");
      console.log(MSG_ERROR_COMUNICACION, error);
    }
  };

  return (
    <div className="Padre">
      <div className="ContenedorNav">
        <Menu></Menu>
      </div>

      <div className="Cuerpo">
        <div className="ContenedorVotar">
          <h2 className="titulo1 centro">ENVIAR VOTO</h2>
          <p className="centro">{semana}º semana del mes</p>
          <p className="centro sinMargin">
            Votos al bueno disponibles: {votosPDisponible}
          </p>
          <p className="centro">
            Votos al malo disponibles: {votosNDisponible}
          </p>

          <div className="SeccionVotar">
            <p className={`centro ${colorMensaje}`}>{mensajeError}</p>

            <table className="TablaVotar">
              <thead>
                <tr>
                  <th>Amigo</th>
                  <th>Voto</th>
                </tr>
              </thead>

              <tbody>
                {candidatos.map((candidato) => (
                  <tr key={candidato.id}>
                    <td>{candidato.nombre}</td>

                    <td>
                      <button
                        className="VotoMejor BotonVoto"
                        onClick={() => votar(candidato.id, true)}
                      >
                        <FaRegThumbsUp />
                      </button>
                      <button
                        className="VotoPeor BotonVoto"
                        onClick={() => votar(candidato.id, false)}
                      >
                        <FaRegThumbsDown />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Votar;
